import {
  MobileFooterInnerWraper,
  MobileFooterLink,
  MobileFooterNav,
} from '@auth/pages/Auth.styles';
import { PoweredByLogo } from '@components';
import { useConfig, useFeatureFlags, useI18n } from '@hooks';
import { Copyright } from './Copyright';

export const MobileFooter = () => {
  const {
    config: {
      logo: { showPoweredBy },
      showFooter,
    },
  } = useConfig();
  const { translate } = useI18n();
  const { canUseHeyLightBranding } = useFeatureFlags();

  return (
    <>
      {showFooter ? (
        <MobileFooterInnerWraper>
          <Copyright
            companyLegalName={translate('GENERAL.COMPANY_LEGAL_NAME')}
          />
          {canUseHeyLightBranding ? null : (
            <MobileFooterNav>
              <MobileFooterLink href="https://www.heidipay.com/en-ch/privacy-policy/">
                {translate('LINK.PRIVACY')}
              </MobileFooterLink>
              <MobileFooterLink href="https://www.heidipay.com/en-ch/terms-conditions/">
                {translate('LINK.TERMS')}
              </MobileFooterLink>
              <MobileFooterLink href="https://help.heidipay.com/">
                {translate('LINK.HELP')}
              </MobileFooterLink>
            </MobileFooterNav>
          )}
        </MobileFooterInnerWraper>
      ) : null}
      {showPoweredBy ? <PoweredByLogo /> : null}
    </>
  );
};
