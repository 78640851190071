import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation } from '@tanstack/react-query';
import { usePortalError } from '@hooks';
import { downloadPdfFromBlob, postDataToEndpoint } from '@utils';
import { Endpoints } from '@utils/enums';

export const useTriggerBalanceTimelinePdfDownload = (
  externalContractUUID?: string,
) => {
  const { handleAxiosError } = usePortalError();

  const { mutate: handleBalanceTimelinePDFDownload } = useMutation<
    ArrayBuffer,
    AxiosError<IError>,
    void
  >(
    () =>
      postDataToEndpoint({
        endpoint: `${Endpoints.DownloadBalanceTimelinePdf}/${externalContractUUID}`,
        requestData: {},
        config: { responseType: 'arraybuffer' },
      }),
    {
      onSuccess: response => {
        downloadPdfFromBlob({
          filename: `${externalContractUUID}_balance_timeline`,
          pdfData: response,
        });
      },
      onError: error => {
        handleAxiosError(error, 'ERROR.FILE_DOWNLOAD');
      },
    },
  );

  return handleBalanceTimelinePDFDownload;
};
