import { useEffect, useState } from 'react';
import { useHasPermission } from '@hooks';
import { getEnv } from '@utils';
import { IUseFeatureFlags } from './types';

export const useFeatureFlags = (): IUseFeatureFlags => {
  const [isProdCheck, setIsProdCheck] = useState(true);
  const [isHeyLightRebrandingEnabled, setIsHeyLightRebrandingEnabled] =
    useState(true);
  useEffect(() => {
    // React will complain that you can't perform a React state update on an unmounted component.
    // There is no memory leak here, though. React just can't see into the useEffect, so it
    // shows the warning, just in case. This will go away when we upgrade to React 18.
    const getEnvCheck = async () => {
      const { isHeyLightRebrandingEnabled: isRebranded, isProd } =
        await getEnv();
      setIsProdCheck(isProd);
      setIsHeyLightRebrandingEnabled(isRebranded);
    };
    getEnvCheck();
  }, []);

  const hasHeyLightPermissions = useHasPermission([
    'consumer_portal.can_use_heylight',
    'merchant_portal.can_use_heylight',
    'ops_portal.can_use_heylight',
  ]);

  const canUseNewLoginPage =
    isHeyLightRebrandingEnabled ||
    window.sessionStorage.getItem('canUseHeyLightLoginPage') === 'true';
  const canUseHeyLightBranding =
    isHeyLightRebrandingEnabled || hasHeyLightPermissions || canUseNewLoginPage;

  return {
    canUseHeyLightBranding,
    canUseNewLoginPage,
    isProdFeatureFlag: isProdCheck,
  };
};
