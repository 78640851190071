import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useOriginator, usePortal } from '@hooks';
import { getIsAuthPage } from '@utils';
import { SupportedLanguages } from '@utils/constants';
import { translations } from './translations';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  SupportedLanguage,
  Translate,
} from './types';
import { useGetTranslationConstants } from './useGetTranslationConstants';
import {
  getInitialLanguageAndLocale,
  getLanguageCode,
  getLanguageStorageKey,
  getLocaleCurrencyValue as getLocaleCurrencyVal,
  getLocaleDate,
  getStoredLanguage,
  setStoredLanguage,
} from './utils';

interface II18nContext {
  selectedLanguage: SupportedLanguage;
  setSelectedLanguage: (language: SupportedLanguage) => void;
}

interface II18nProvider {
  children: JSX.Element | JSX.Element[];
}

const I18nContext = createContext<II18nContext>({
  selectedLanguage: 'en',
  setSelectedLanguage: () => {},
});

const { initialLanguage, locale } = getInitialLanguageAndLocale(
  navigator?.languages,
);

export const I18nProvider = ({ children }: II18nProvider) => {
  const { pathname } = useLocation();
  const isAuthPage = getIsAuthPage(pathname);

  const { isCompass, originator } = useOriginator();
  const { isOpsPortal, portal } = usePortal();

  const isItalian = isCompass || /IT/.test(pathname);

  const [selectedLanguage, setSelectedLanguage] = useState<SupportedLanguage>(
    isItalian ? SupportedLanguages.ITALIAN : initialLanguage,
  );

  const messages = translations[selectedLanguage];

  useEffect(() => {
    if (isOpsPortal) {
      setSelectedLanguage(SupportedLanguages.ENGLISH);
    } else if (portal && isCompass) {
      setSelectedLanguage(SupportedLanguages.ITALIAN);
    } else {
      const languageKey = getLanguageStorageKey({
        isCompass,
        isOpsPortal,
        originator,
        portal,
      });
      const storedLanguage = getStoredLanguage(languageKey);

      if (storedLanguage) {
        setSelectedLanguage(storedLanguage);
      }
    }
  }, [isCompass, isOpsPortal, originator, portal]);

  useEffect(() => {
    const canStoreLanguage = isCompass || isOpsPortal || !isAuthPage;

    if (canStoreLanguage) {
      const languageKey = getLanguageStorageKey({
        isCompass,
        isOpsPortal,
        originator,
        portal,
      });

      setStoredLanguage({
        key: languageKey,
        language: selectedLanguage,
      });
    }
  }, [
    isAuthPage,
    isCompass,
    isOpsPortal,
    originator,
    portal,
    selectedLanguage,
  ]);

  const value = useMemo(
    () => ({
      selectedLanguage,
      setSelectedLanguage,
    }),
    [selectedLanguage],
  );

  return (
    <I18nContext.Provider value={value}>
      <IntlProvider locale={selectedLanguage} messages={messages}>
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  );
};

interface IUseI18n extends II18nContext {
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  languageCode: string;
  translate: Translate;
}

export const useI18n = (): IUseI18n => {
  const translationConstants = useGetTranslationConstants();
  const intl = useIntl();
  const context = useContext(I18nContext);

  if (context === undefined) {
    throw new Error('useI18n must be used within an I18nProvider');
  }

  const languageCode = getLanguageCode({
    language: context.selectedLanguage,
    locale,
  });

  const getLocaleCurrencyValue = useCallback(
    ({ currency, value }) =>
      getLocaleCurrencyVal({ currency, languageCode, value }),
    [languageCode],
  );

  return {
    ...context,

    getLocaleCurrencyValue,

    getLocaleDate: ({ date, formatOverride, includeTime }) =>
      getLocaleDate({ date, formatOverride, includeTime, languageCode }),

    languageCode,

    translate: useCallback(
      (id, interpolations = {}) =>
        intl.formatMessage(
          { id },
          { ...interpolations, ...translationConstants },
        ),
      [intl, translationConstants],
    ),
  };
};
