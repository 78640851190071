import styled from 'styled-components';
import { ValueOf } from '@appTypes';
import { useConfig } from '@hooks/useConfig';
import { LogoTypes, LogoVariants } from '@utils/constants';
import { ImageLogo } from './ImageLogo';
import { SVGLogo } from './SVGLogo';

interface ILogoProps {
  fill?: ValueOf<typeof LogoVariants>;
}

const OuterWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 991.98px) {
    h1 & {
      justify-content: center;
    }
  }
`;

const InnerWrapper = styled.span`
  display: inline-block;
  width: 150px;
  overflow: hidden;
`;

export const Logo = ({ fill = LogoVariants.DARK }: ILogoProps) => {
  const {
    config: { canUseHeyLightBranding, logo },
  } = useConfig();

  const Components = {
    [LogoTypes.PNG]: 'altTxt' in logo && 'url' in logo && (
      <ImageLogo altTxt={logo.altTxt} url={logo.url} variant={fill} />
    ),
    [LogoTypes.SVG]: (
      <SVGLogo
        component={'component' in logo && logo.component}
        fill={fill}
        title={'title' in logo && logo.title}
      />
    ),
  };

  const showHeyLightLogo =
    canUseHeyLightBranding && 'altTxt' in logo && 'url' in logo;

  const Component = showHeyLightLogo ? (
    <ImageLogo altTxt={logo.altTxt} url={logo.url} variant={fill} />
  ) : (
    'type' in logo && Components[logo.type]
  );

  return (
    <OuterWrapper>
      <InnerWrapper>{Component}</InnerWrapper>
    </OuterWrapper>
  );
};
