import { ValueOf } from '@appTypes';
import { Originators, PathnamePrefixes } from '@utils/constants';

export type PathnamePrefix =
  | ValueOf<typeof PathnamePrefixes>
  | '/HeyLight/IT'
  | '/HeyLight/CH'
  | '';

export const getPathnamePrefixForOriginator = (
  originator: keyof typeof PathnamePrefixes,
  canUseNewLoginPage: boolean,
): PathnamePrefix => {
  if (canUseNewLoginPage) {
    if (originator === Originators.HEIDIPAY) {
      return '/HeyLight/CH';
    }
    return '/HeyLight/IT';
  }

  if (originator === Originators.HEIDIPAY) {
    return '';
  }

  return PathnamePrefixes[originator];
};
