import { useSelector } from 'react-redux';

import { selectMerchantSettingGroup } from '@selectors/index';
import { SettingKeyToInterfaceMap } from './types';

export const useMerchantSettingGroup = () => {
  const {
    apple_product_tracking_enabled: appleProductTrackingEnabled,
    application_page_origination_channel_type: applicationChannelType,
    hide_orders_page: hideOrdersPage,
    instore_is_discount_code_enabled: instoreIsDiscountCodeEnabled,
    is_return_customer_tracking_enabled: isReturnCustomerTrackingEnabled,
    merchant_settings: merchantSettings,
    show_applications_page: showApplicationsPage,
  } = useSelector(selectMerchantSettingGroup) ?? {};

  const getMerchantSetting = <K extends keyof SettingKeyToInterfaceMap>(
    key: K,
  ): SettingKeyToInterfaceMap[K] | undefined =>
    merchantSettings?.find(setting => setting.setting_type === key)
      ?.setting_json as SettingKeyToInterfaceMap[K];

  return {
    appleProductTrackingEnabled,
    applicationChannelType,
    getMerchantSetting,
    hideOrdersPage,
    instoreIsDiscountCodeEnabled,
    isReturnCustomerTrackingEnabled,
    showApplicationsPage,
  };
};
