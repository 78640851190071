import { useCallback } from 'react';
import { useI18n } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { IModalProps } from '@hooks/useModal/types';
import {
  useAssignOverrideManualBucketQuery,
  useGetManualBucketsListQuery,
} from '@pages/ContractDetails/hooks/queries';
import { QueryKeys } from '@utils/constants';
import { AssignOverrideBucketForm } from './AssignOverrideBucketForm';
import { IAssignOverrideBucket } from './types';

export const useAssignOverrideBucketModal = ({
  closeModal,
  contractUuid,
  currentBucketId,
  formStatusMessage,
}: IAssignOverrideBucket): IModalProps => {
  const { translate } = useI18n();

  const collectionsQueryKey = [QueryKeys.COLLECTIONS_DATA, contractUuid];

  const { bucketListData, isLoadingBucketList } =
    useGetManualBucketsListQuery();

  const { assignOverrideBucket, isAssigningOverrideBucket } =
    useAssignOverrideManualBucketQuery({
      closeModal,
      collectionsQueryKey,
      contractUuid,
    });

  const handleSubmit = useCallback(
    async data => {
      assignOverrideBucket(data);
    },
    [assignOverrideBucket],
  );

  const formID = 'assignOverrideBucket';
  const isLoading = isAssigningOverrideBucket || isLoadingBucketList;
  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <AssignOverrideBucketForm
        bucketList={bucketListData?.buckets ?? []}
        currentBucketId={currentBucketId}
        formID={formID}
        handleSubmit={handleSubmit}
      />
    </ModalStatusRouter>
  );

  return {
    allowoverflow: true,
    formID,
    isDangerousSubmit: true,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.ASSIGN_OVERRIDE_BUCKET'),
  };
};
