import { useLocation } from 'react-router-dom';
import { useConfig, usePortal, useUserDetails } from '@hooks';
import { NavItems } from '@hooks/useConfig/utils/NavItems';
import { NavSectionHeadingKeys } from '@hooks/useConfig/utils/NavSectionHeadingKeys';
import { MenuSection } from './MenuSection';
import { INavItem, INavSection } from './types';

const AgencyNavSectionHeadings = [
  NavSectionHeadingKeys.ACCOUNT_MANAGEMENT,
  NavSectionHeadingKeys.CONTRACTS,
];

const AgencyNavSectionItems = [
  NavItems.ACCOUNT_SETTINGS,
  NavItems.ALL_CONTRACTS,
  NavItems.COLLECTIONS,
  NavItems.LOGOUT,
];

const filterAgencyItems = (item: INavItem) =>
  AgencyNavSectionItems.some(
    agencyItem => agencyItem.translationKey === item.translationKey,
  );

const InstoreItems = [NavItems.CREATE_NEW_PLAN];

const filterInstoreItems =
  (hasInstoreProfileGroup: boolean, isMerchantPortal: boolean) =>
  (item: INavItem) =>
    !isMerchantPortal || hasInstoreProfileGroup || !InstoreItems.includes(item);

export function MenuList() {
  const { pathname } = useLocation();
  const { isMerchantPortal, portal } = usePortal();
  const {
    config: { navigation },
  } = useConfig();
  const { hasInstoreProfileGroup, isAgencyUser } = useUserDetails();
  // config will determine which items can be shown...
  return (
    <>
      {navigation
        // ... but then we filter the sections based on user type ...
        .filter((menuEntry: INavSection) =>
          isAgencyUser
            ? AgencyNavSectionHeadings.some(
                heading => heading === menuEntry.heading,
              )
            : true,
        )
        // ... and we filter the remaining nav items based on user type ...
        .map((menuEntry: INavSection) => {
          if (isAgencyUser) {
            return {
              ...menuEntry,
              items: menuEntry.items.filter(filterAgencyItems),
            };
          }
          return menuEntry;
        })
        // ... and we filter on if the merchant has active instore profile group ...
        .map((menuEntry: INavSection) => ({
          ...menuEntry,
          items: menuEntry.items.filter(
            filterInstoreItems(hasInstoreProfileGroup, isMerchantPortal),
          ),
        }))
        // ... and we filter the nav items based on permissions ...
        .filter((menuEntry: INavSection) => {
          const scopeFinder = (scope: string) =>
            RegExp(`${portal}_portal.`).test(scope);
          return menuEntry.items.find(item => item.scopes.find(scopeFinder));
        })
        .map(({ heading, items }: INavSection) => (
          <MenuSection
            currentPathname={pathname}
            heading={heading ?? ''}
            items={items}
            key={`section-${heading}`}
          />
        ))}
    </>
  );
}
