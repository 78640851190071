import { useMemo } from 'react';
import { ITabData } from '@components/Tabs/types';
import { useI18n } from '@hooks';
import {
  getPaymentScheduleTableColumns,
  mapPaymentScheduleData,
} from '@pages/ContractDetails/utils';
import { getScheduleVersions } from '@pages/ContractDetails/utils/getScheduleVersions';
import { ContractPaymentScheduleHistoryColumns } from '@pages/ContractDetails/utils/types';
import {
  IBalance,
  IOpsPortalMonetaContract,
} from '@schemas/opsPortal/types/monetaContract';
import { TabEventKeys, TableNames } from '@utils/constants';

export const useContractPaymentScheduleHistoryTab = (
  monetaContractData?: IOpsPortalMonetaContract,
) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  // In schedule history we tab we don't want to show manual payments/refunds
  // i.e. where there wasn't an amount due at the that time
  const monetaContractDataWithoutManualPayments = monetaContractData?.balances
    ? {
        ...monetaContractData,
        balances: monetaContractData.balances.filter(
          (balance: IBalance) => Number(balance.amount_due) !== 0,
        ),
      }
    : monetaContractData;

  const contractPaymentScheduleHistoryData = mapPaymentScheduleData({
    data: monetaContractDataWithoutManualPayments,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
    isScheduleHistory: true,
  });

  const contractPaymentScheduleHistoryColumns = useMemo(
    () =>
      getPaymentScheduleTableColumns<ContractPaymentScheduleHistoryColumns>(
        translate,
        true,
      ),
    [translate],
  );

  const scheduleVersionOptions = getScheduleVersions(monetaContractData);

  const defaultScheduleVersion = scheduleVersionOptions.length
    ? scheduleVersionOptions[0].id
    : '';

  const contractPaymentScheduleHistoryTab: ITabData = {
    eventKey: TabEventKeys.CONTRACT_PAYMENT_SCHEDULE_HISTORY,
    title: translate('OPS_PORTAL.TITLE.SCHEDULE_HISTORY'),
    data: contractPaymentScheduleHistoryData,
    columns: contractPaymentScheduleHistoryColumns,
    tableName: TableNames.PAYMENT_SCHEDULE_HISTORY,
    tableSpecificProps: {
      defaultScheduleVersion,
      scheduleVersionOptions,
    },
    className: 'payment-schedule',
    rowClasses: contractPaymentScheduleHistoryData.map(entry =>
      !entry.focused ? 'future-entry' : '',
    ),
  };

  return contractPaymentScheduleHistoryTab;
};
