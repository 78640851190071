import { useMemo } from 'react';
import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { createColumnHelper } from '@tanstack/react-table';
import { ITabData } from '@components/Tabs/types';
import { useI18n } from '@hooks';
import { sortAssignmentHistoryData } from '@pages/ContractDetails/utils/sortAssignmentHistoryData';
import {
  AssignmentHistoryColumns,
  IAssignmentHistoryTableData,
} from '@pages/ContractDetails/utils/types';
import { TabEventKeys, TableNames } from '@utils/constants';
import { useGetAssignmentHistoryQuery } from '../queries';

export const useAssignmentHistoryTab = (externalContractUUID?: string) => {
  const {
    assignmentHistoryData,
    assignmentHistoryError,
    assignmentHistoryLoading,
  } = useGetAssignmentHistoryQuery({
    externalContractUUID,
  });

  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const sortedData =
    assignmentHistoryData && sortAssignmentHistoryData(assignmentHistoryData);
  const assignmentHistoryTableData: IAssignmentHistoryTableData[] = (
    sortedData?.assignment_history ?? []
  ).map(dataRow => {
    const {
      agency,
      amount_collected: amountCollected,
      arrears_at_start: arrearsAtStart,
      assignment_end: assignmentEnd,
      assignment_number: assignmentNumber,
      assignment_start: assignmentStart,
      bucket,
    } = dataRow;

    return addPlaceholderForEmptyValues({
      amountCollected: getLocaleCurrencyValue({
        currency: amountCollected?.currency,
        value: amountCollected?.amount,
      }),
      bucket,
      arrearsAtStart: getLocaleCurrencyValue({
        currency: arrearsAtStart?.currency,
        value: arrearsAtStart?.amount,
      }),
      assignmentEnd: assignmentEnd
        ? getLocaleDate({ date: assignmentEnd })
        : null,
      assignmentStart: assignmentStart
        ? getLocaleDate({ date: assignmentStart })
        : null,
      assignmentNumber,
      agency,
    });
  });

  const columnHelper = createColumnHelper<IAssignmentHistoryTableData>();

  const assignmentHistoryColumns: AssignmentHistoryColumns = useMemo(
    () => [
      columnHelper.accessor('assignmentStart', {
        header: translate('OPS_PORTAL.LABEL.ASSIGNMENT_START'),
      }),
      columnHelper.accessor('assignmentEnd', {
        header: translate('OPS_PORTAL.LABEL.ASSIGNMENT_END'),
      }),
      columnHelper.accessor('bucket', {
        header: translate('OPS_PORTAL.LABEL.BUCKET'),
      }),
      columnHelper.accessor('agency', {
        header: translate('OPS_PORTAL.LABEL.AGENCY'),
      }),
      columnHelper.accessor('arrearsAtStart', {
        header: translate('OPS_PORTAL.LABEL.ARREARS_AT_START'),
      }),
      columnHelper.accessor('amountCollected', {
        header: translate('OPS_PORTAL.LABEL.AMOUNT_COLLECTED'),
      }),
    ],
    [translate, columnHelper],
  );

  const assignmentHistoryTab: ITabData = {
    eventKey: TabEventKeys.ASSIGNMENT_HISTORY,
    title: translate('OPS_PORTAL.TITLE.ASSIGNMENT_HISTORY'),
    columns: assignmentHistoryColumns,
    tableName: TableNames.ASSIGNMENT_HISTORY,
    data: assignmentHistoryTableData,
    error: assignmentHistoryError,
    isLoading: assignmentHistoryLoading,
  };

  return assignmentHistoryTab;
};
