import { useFeatureFlags } from '@hooks';

interface ITranslationConstants {
  companyLegalName: string;
  companyName: string;
}

export const legacyTranslationConstants: ITranslationConstants = {
  companyLegalName: 'Heidi Pay AG',
  companyName: 'HeidiPay',
};

export const heyLightTranslationConstants: ITranslationConstants = {
  companyLegalName: 'HeyLight AG',
  companyName: 'HeyLight',
};

export const useGetTranslationConstants = () => {
  const { canUseHeyLightBranding } = useFeatureFlags();

  return canUseHeyLightBranding
    ? heyLightTranslationConstants
    : legacyTranslationConstants;
};
