import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n, usePortalError } from '@hooks';
import { IBucket } from '@schemas/opsPortal/types/collectionsListManualBuckets';
import { postDataToEndpoint, wait } from '@utils';
import { Endpoints } from '@utils/enums';

interface IUseAssignOverrideManualBucketQuery {
  closeModal?: () => void;
  collectionsQueryKey: QueryKey;
  contractUuid: string;
}

interface IAssignOverrideBucketRequestData {
  bucket_override: string;
}

export const useAssignOverrideManualBucketQuery = ({
  closeModal,
  collectionsQueryKey,
  contractUuid,
}: IUseAssignOverrideManualBucketQuery) => {
  const { translate } = useI18n();
  const { message } = useToast(translate);
  const { handleAxiosError } = usePortalError();
  const queryClient = useQueryClient();

  const { isLoading: isAssigningOverrideBucket, mutate: assignOverrideBucket } =
    useMutation<IBucket, AxiosError<IError>, IAssignOverrideBucketRequestData>(
      requestData =>
        postDataToEndpoint({
          endpoint: `${Endpoints.AssignOverrideBucket}/${contractUuid}`,
          requestData: { bucket_id: requestData?.bucket_override },
        }),
      {
        onSuccess: async () => {
          // Timer is needed here as it takes a second for collections to update
          await wait(1000);
          queryClient.invalidateQueries(collectionsQueryKey);
          message.success(
            'OPS_PORTAL.STATUS.ASSIGN_OVERRIDE_BUCKET_SUCCESSFUL',
          );
          closeModal?.();
        },
        onError: error => {
          handleAxiosError(
            error,
            'OPS_PORTAL.STATUS.UNABLE_TO_ASSIGN_OVERRIDE_BUCKET',
          );
        },
      },
    );

  return {
    isAssigningOverrideBucket,
    assignOverrideBucket,
  };
};
