import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DropdownRHF as Dropdown } from '@components';
import { useI18n } from '@hooks';
import { ValidationErrorKeys } from '@utils/enums';
import {
  IAssignOverrideBucketRequest,
  IAssignOverrideBucketForm,
} from './types';

export const AssignOverrideBucketForm = ({
  bucketList,
  currentBucketId,
  formID,
  handleSubmit,
}: IAssignOverrideBucketForm) => {
  const { translate } = useI18n();
  const defaultValues = {
    bucket_override: currentBucketId,
  };
  const [selectedBucket, setSelectedBucket] = useState(currentBucketId);

  const validationSchema = () => {
    return Yup.object({
      bucket_override: Yup.string()
        .required(translate(ValidationErrorKeys.Required))
        .test(
          'not-duplicate',
          translate(ValidationErrorKeys.ValueHasNotChanged),
          () => selectedBucket !== currentBucketId,
        ),
    });
  };

  const methods = useForm<IAssignOverrideBucketRequest>({
    defaultValues,
    resolver: yupResolver(validationSchema()),
  });

  const bucketOverride = methods.watch('bucket_override');
  useEffect(() => {
    setSelectedBucket(bucketOverride);
  }, [bucketOverride]);

  const bucketOptions = bucketList.map(manualBucket => ({
    id: manualBucket.bucket_id,
    name: `${manualBucket.bucket_id} - ${manualBucket.name}`,
  }));

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={methods.handleSubmit(handleSubmit)}>
        {!bucketOptions.length ? (
          <p>{translate('OPS_PORTAL.LABEL.NO_BUCKETS_AVAILABLE')}</p>
        ) : (
          <Dropdown
            label={translate('OPS_PORTAL.TITLE.ASSIGN_OVERRIDE_BUCKET')}
            name="bucket_override"
            options={bucketOptions}
          />
        )}
      </form>
    </FormProvider>
  );
};
