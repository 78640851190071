import { ModalKeys } from '../enums';
import { IModalProps } from '../types';
import { useAddCertificateOfInterestModal } from './useAddCertificateOfInterestModal';
import { useAddNoteModal } from './useAddNoteModal';
import { useAdjustContractValueModal } from './useAdjustContractValueModal';
import { useAllocationDetailsModal } from './useAllocationDetailsModal';
import { useApplicantBlockModal } from './useApplicantBlockModal';
import { useAssignOverrideBucketModal } from './useAssignOverrideBucketModal';
import { useChangeCardDetailsModal } from './useChangeCardDetailsModal';
import { useChangeIbanModal } from './useChangeIbanModal';
import { useChangePaymentDatesModal } from './useChangePaymentDatesModal';
import { useConfirmDispatchModal } from './useConfirmDispatchModal';
import { useContractPaymentModal } from './useContractPaymentModal';
import { useDeleteFeesModal } from './useDeleteFeesModal';
import { useDeleteUserModal } from './useDeleteUserModal';
import { useDownloadCustomerAgreementModal } from './useDownloadCustomerAgreementModal';
import { useDownloadQrPdfModal } from './useDownloadQrPdfModal';
import { useDownloadSettlementsReportModal } from './useDownloadSettlementsReportModal';
import { useEarlySettlementQuoteCreateModal } from './useEarlySettlementQuoteCreateModal';
import { useEarlySettlementQuoteDisplayModal } from './useEarlySettlementQuoteDisplayModal';
import { useEditAddressModal } from './useEditAddressModal';
import { useEditNameModal } from './useEditNameModal';
import { useEditPhoneModal } from './useEditPhoneModal';
import { useEmailUserModal } from './useEmailUserModal';
import { useFileUploadModal } from './useFileUploadModal';
import { useOrderPaymentModal } from './useOrderPaymentModal';
import { usePasswordModal } from './usePasswordModal';
import { usePausePaymentsModal } from './usePausePaymentsModal';
import { usePaymentLinkModal } from './usePaymentLinkModal';
import { useSettlementAdjustmentModal } from './useSettlementAdjustmentModal';
import { useTriggerPaymentModal } from './useTriggerPaymentModal';
import { useUpdateArrearsManagedByModal } from './useUpdateArrearsManagedByModal';

import { useUserModal } from './useUserModal';
import { useViewCommunicationModal } from './useViewCommunicationModal';
import { useViewRawDataModal } from './useViewRawDataModal';

export { useConfirmModal } from './useConfirmModal';
export { usePaymentModal } from './usePaymentModal';

// Without very complex typing, we need to use `any` for the props
// here to allow any type of properties to be used on the modal hooks
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hooks: Record<ModalKeys, (props: any) => IModalProps | null> = {
  [ModalKeys.AddCertificateOfInterest]: useAddCertificateOfInterestModal,
  [ModalKeys.AddNote]: useAddNoteModal,
  [ModalKeys.AdjustContractValue]: useAdjustContractValueModal,
  [ModalKeys.AllocationDetails]: useAllocationDetailsModal,
  [ModalKeys.AssignOverrideBucket]: useAssignOverrideBucketModal,
  [ModalKeys.CancelContract]: useAdjustContractValueModal,
  [ModalKeys.ChangeCardDetails]: useChangeCardDetailsModal,
  [ModalKeys.ChangeIban]: useChangeIbanModal,
  [ModalKeys.ChangePaymentDate]: useChangePaymentDatesModal,
  [ModalKeys.ConfirmDispatch]: useConfirmDispatchModal,
  [ModalKeys.CreateApplicantBlock]: useApplicantBlockModal,
  [ModalKeys.DownloadCustomerAgreement]: useDownloadCustomerAgreementModal,
  [ModalKeys.EarlySettlementsDisplay]: useEarlySettlementQuoteDisplayModal,
  [ModalKeys.EarlySettlementsCreateQuote]: useEarlySettlementQuoteCreateModal,
  [ModalKeys.ContractAdjustments]: useSettlementAdjustmentModal,
  [ModalKeys.ContractManualPayments]: useContractPaymentModal,
  [ModalKeys.ContractPrepayment]: useContractPaymentModal,
  [ModalKeys.ContractReimbursement]: useContractPaymentModal,
  [ModalKeys.CreateUser]: useUserModal,
  [ModalKeys.DownloadSettlementsReport]: useDownloadSettlementsReportModal,
  [ModalKeys.DownloadQrPdfModal]: useDownloadQrPdfModal,
  [ModalKeys.DeleteFees]: useDeleteFeesModal,
  [ModalKeys.DeleteUser]: useDeleteUserModal,
  [ModalKeys.EditAddress]: useEditAddressModal,
  [ModalKeys.EditName]: useEditNameModal,
  [ModalKeys.EditPhone]: useEditPhoneModal,
  [ModalKeys.EditUser]: useUserModal,
  [ModalKeys.EmailUser]: useEmailUserModal,
  [ModalKeys.FileUpload]: useFileUploadModal,
  [ModalKeys.ModifyApplicantBlock]: useApplicantBlockModal,
  [ModalKeys.OrderPrepayment]: useOrderPaymentModal,
  [ModalKeys.OrderRefund]: useOrderPaymentModal,
  [ModalKeys.PaymentLink]: usePaymentLinkModal,
  [ModalKeys.Password]: usePasswordModal,
  [ModalKeys.PausePayments]: usePausePaymentsModal,
  [ModalKeys.RemoveApplicantBlock]: useApplicantBlockModal,
  [ModalKeys.TriggerPayment]: useTriggerPaymentModal,
  [ModalKeys.UpdateArrearsManagedBy]: useUpdateArrearsManagedByModal,
  [ModalKeys.ViewCommunication]: useViewCommunicationModal,
  [ModalKeys.ViewRawData]: useViewRawDataModal,
};
