import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ValueOf } from '@appTypes';
import { Card, ScheduledPayments, ShoppingCartDetails } from '@components';
import { GridCol, GridRow } from '@components/Common.styles';
import { IShoppingCartOrderDetails } from '@components/ShoppingCartDetails/types';
import {
  ModalKeys,
  useFeatureFlags,
  useI18n,
  useModal,
  useOriginator,
} from '@hooks';
import { IConsumerPortalArrearsData } from '@schemas/consumerPortal/types/arrearsData';
import { IConsumerPortalOrderDetails } from '@schemas/consumerPortal/types/ordersDetails';
import { PaymentMethods, PaymentTypes, QueryKeys } from '@utils/constants';
import {
  checkPropertiesExist,
  getBillingInfoProps,
  getScheduledPayments,
} from '../utils';
import { getConsumerOrderSummaryProps } from '../utils/getConsumerOrderSummaryProps';
import { IScheduledPayment } from './types';
import { BillingInfo, OrderSummaryCard, CompassSupportTextAndLink } from '.';

interface IOrderDetailsDisplayConsumer {
  arrearsData?: IConsumerPortalArrearsData;
  orderDetailsData: IConsumerPortalOrderDetails;
  queryKey: ValueOf<typeof QueryKeys>;
}

export const OrderDetailsDisplayConsumer = ({
  arrearsData,
  orderDetailsData,
  queryKey,
}: IOrderDetailsDisplayConsumer) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const { uuid } = useParams();
  const { isCompass, isHeidiPay, isPagolightPro } = useOriginator();
  const featureFlags = useFeatureFlags();
  const { canUseHeyLightBranding } = featureFlags;

  const dataObj = orderDetailsData[0];

  const { hasPayments, hasProducts, permissions } = checkPropertiesExist({
    data: dataObj,
    isPagolightPro,
  });

  const billingInfoProps = getBillingInfoProps(dataObj);
  const scheduledPayments: IScheduledPayment[] = hasPayments
    ? getScheduledPayments({
        getLocaleCurrencyValue,
        getLocaleDate,
        schedule: dataObj.receivables,
      })
    : [];
  const nextPayment: IScheduledPayment = scheduledPayments[0] ?? {
    dueDate: '',
    paymentAmount: '',
  };

  const isDirectDebit = dataObj?.payment_method === PaymentMethods.DIRECT_DEBIT;
  const changeCardDetailsModal = useModal(ModalKeys.ChangeCardDetails, {
    isDirectDebit,
    isHeidiPay,
    isPagolightPro,
    orderId: uuid,
    hasHeader: isHeidiPay,
  });
  const billingInformationCardButtons = [
    ...(isDirectDebit && isCompass ? [] : [changeCardDetailsModal?.ctaProps]),
  ].filter(Boolean);

  const orderSummaryProps = getConsumerOrderSummaryProps({
    arrearsData,
    getLocaleCurrencyValue,
    getLocaleDate,
    isCompass,
    canUseHeyLightBranding,
    nextPayment,
    orderDetailsData: dataObj,
    translate,
  });

  const summaryComponent = (
    <OrderSummaryCard
      canShowPaymentButton={dataObj.payment_method === PaymentMethods.CARD}
      orderDetailsData={dataObj}
      orderSummaryProps={orderSummaryProps}
      paymentModalKey={ModalKeys.OrderPrepayment}
      paymentType={PaymentTypes.PREPAYMENT}
      permissions={permissions}
      queryKey={queryKey}
      uuid={uuid as string}
    />
  );

  return (
    <>
      {hasPayments ? (
        <GridRow>
          <GridCol>{summaryComponent}</GridCol>
          <GridCol>
            <Card title={translate('TITLE.FUTURE_PAYMENTS_SCHEDULE')}>
              <ScheduledPayments payments={scheduledPayments} />
            </Card>
          </GridCol>
        </GridRow>
      ) : (
        summaryComponent
      )}

      {hasProducts ? (
        <Card title={translate('TITLE.SHOPPING_CART_DETAILS')}>
          <ShoppingCartDetails
            displayTitle={false}
            orderDetails={dataObj.order_details as IShoppingCartOrderDetails}
          />
        </Card>
      ) : null}

      <Card
        buttons={billingInformationCardButtons}
        title={
          isHeidiPay
            ? translate('TITLE.BILLING_INFORMATION')
            : translate('TITLE.DEBIT_METHOD')
        }
      >
        <BillingInfo {...billingInfoProps} />
        {isCompass && dataObj.payment_method === PaymentMethods.DIRECT_DEBIT ? (
          <Box sx={{ textAlign: 'right' }}>
            <CompassSupportTextAndLink
              canUseHeyLightBranding={canUseHeyLightBranding}
              supportTextKey="PAGE.ORDERS.TO_CHANGE_THE_IBAN"
              translate={translate}
            />
          </Box>
        ) : null}
      </Card>
      {changeCardDetailsModal?.Modal}
    </>
  );
};
