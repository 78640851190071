/* eslint-disable max-lines */
import { Fragment } from 'react';
import { AxiosError } from 'axios';
import { QueryKey } from '@tanstack/react-query';
import { ValueOf } from '@appTypes';
import { Spinner } from '@auth/pages/Auth.styles';
import {
  Card,
  NoDataAvailable,
  NotificationBanner,
  QueryStateRouter,
} from '@components';
import {
  useI18n,
  useOriginator,
  useApplicantBlocking,
  useCollectionAgencies,
} from '@hooks';
import { IOpsPortalBlockExternalCollectionsReferral } from '@schemas/opsPortal/types/blockExternalCollectionsReferral';
import { IOpsPortalCollectionsDetails } from '@schemas/opsPortal/types/collectionsDetails';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { IOpsPortalPauseCommunications } from '@schemas/opsPortal/types/pauseCommunications';
import { getFormattedDate } from '@utils';
import { RawStatuses, Views } from '@utils/constants';
import { useContractModals, useDetailsCardActions } from '../hooks';
import {
  useConfirmContractQuery,
  useTriggerDocumentUploadQuery,
} from '../hooks/queries';
import { useGetManualBucketsListQuery } from '../hooks/queries/useGetManualBucketsListQuery';
import { getContractDetailsNotifications } from '../utils/getContractDetailsNotifications';
import { TabsComponent } from './TabsComponent';

export interface IDetailsCardProps {
  collectionsBlockAutoReferralData?: IOpsPortalBlockExternalCollectionsReferral;
  collectionsBlockAutoReferralError?: AxiosError | null;
  collectionsData?: IOpsPortalCollectionsDetails;
  contractData?: IContractDetails;
  contractDataError?: AxiosError | null;
  contractDataQueryKey?: QueryKey;
  contractUuid?: string;
  externalContractUUID?: string;
  invalidateMonetaData?: () => void;
  isArrearsCurrentlyAssignedToHeidi: boolean;
  isArrearsToBeManagedByHeidi: boolean;
  isCollectionsDataError: boolean;
  isInternalOpsUser: boolean;
  isLiveContracts: boolean;
  isLoading: boolean;
  monetaContractData?: IOpsPortalMonetaContract;
  pauseCommunicationsData?: IOpsPortalPauseCommunications;
  pauseCommunicationsError?: AxiosError | null;
  view: ValueOf<typeof Views>;
}

export const DetailsCard = ({
  collectionsBlockAutoReferralData,
  collectionsBlockAutoReferralError,
  collectionsData,
  contractData,
  contractDataError,
  contractDataQueryKey,
  contractUuid,
  externalContractUUID,
  invalidateMonetaData,
  isArrearsCurrentlyAssignedToHeidi,
  isArrearsToBeManagedByHeidi,
  isCollectionsDataError,
  isInternalOpsUser,
  isLiveContracts,
  isLoading,
  monetaContractData,
  pauseCommunicationsData,
  pauseCommunicationsError,
  view,
}: IDetailsCardProps) => {
  const { translate } = useI18n();
  const { isCompass } = useOriginator();

  const { collectionAgencies, isCollectionAgenciesLoading } =
    useCollectionAgencies();

  const {
    applicantBlockModalCtaProps,
    applicantBlockModals,
    error: blockStatusError,
    isLoading: isBlockStatusLoading,
    notification: applicantBlockingNotification,
  } = useApplicantBlocking({
    contractUuid,
  });

  const notifications = getContractDetailsNotifications({
    collectionAgencies,
    collectionsBlockAutoReferralData,
    contractData,
    isArrearsCurrentlyAssignedToHeidi,
    translate,
  });

  const { bucketListData, bucketListError, isLoadingBucketList } =
    useGetManualBucketsListQuery();

  const translatedDefaultContractDetailsTitle = translate(
    'OPS_PORTAL.TITLE.CONTRACT_DETAILS',
  );

  const cardTitle: Partial<Record<ValueOf<typeof Views>, string>> = {
    awaiting_dispatch: translate('OPS_PORTAL.TITLE.AWAITING_DISPATCH_DETAILS'),
    contracts: translatedDefaultContractDetailsTitle,
    overpaid_contracts: translatedDefaultContractDetailsTitle,
    terminable_contracts: translatedDefaultContractDetailsTitle,
  };

  const isPaused = Boolean(contractData?.pause_payment_processing);
  const paymentsPausedTillDate = contractData?.payment_paused_till_date;

  const alerts = [];
  if (isPaused) {
    const text = paymentsPausedTillDate
      ? `${translate(
          'OPS_PORTAL.STATUS.PAYMENTS_NOT_COLLECTED_TILL',
        )} ${getFormattedDate(paymentsPausedTillDate)}`
      : translate('OPS_PORTAL.STATUS.PAYMENTS_NOT_AUTOMATICALLY_COLLECTED');

    alerts.push({
      rawStatus: RawStatuses.PAYMENTS_PAUSED,
      status: text,
    });
  }

  const modals = useContractModals({
    bucketListData,
    collectionAgencies,
    collectionsData,
    contractData,
    contractDataQueryKey,
    contractUuid,
    externalContractUUID,
    invalidateMonetaData,
    isInternalOpsUser,
    isLiveContracts,
    isPaused,
    monetaContractData,
    paymentsPausedTillDate,
  });

  const { confirmContract, isConfirmingContract } = useConfirmContractQuery({
    contractDataQueryKey,
    contractUuid,
  });

  const { isTriggeringDocumentUpload, triggerDocumentUpload } =
    useTriggerDocumentUploadQuery({
      contractDataQueryKey,
      contractUuid,
    });

  const compassContractButtons = isCompass
    ? [
        {
          ...(isTriggeringDocumentUpload
            ? { additionalButtonContent: <Spinner extraClassName="pr-5" /> }
            : {}),
          clickHandler: triggerDocumentUpload,
          key: 'uploadCompassContractDocuments',
          text: translate('OPS_PORTAL.BUTTON.TRIGGER_DOCUMENT_UPLOAD'),
        },
        {
          ...(isConfirmingContract
            ? { additionalButtonContent: <Spinner extraClassName="pr-5" /> }
            : {}),
          clickHandler: confirmContract,
          key: 'confirmCompassContractDispatch',
          text: translate('OPS_PORTAL.BUTTON.CONFIRM_CONTRACT_DISPATCH'),
        },
      ]
    : [];

  const detailsCardActionButtons = useDetailsCardActions({
    externalContractUUID,
    pauseCommunications: pauseCommunicationsData,
    collectionsBlockAutoReferral: collectionsBlockAutoReferralData,
  });

  const buttons = [
    ...modals.map(modal => modal?.ctaProps),
    ...compassContractButtons,
    ...applicantBlockModalCtaProps,
    ...detailsCardActionButtons,
  ];

  return (
    <Card alerts={alerts} buttons={buttons} title={cardTitle[view] as string}>
      <QueryStateRouter
        error={
          blockStatusError ||
          contractDataError ||
          collectionsBlockAutoReferralError ||
          pauseCommunicationsError ||
          bucketListError
        }
        isLoading={
          isLoading ||
          isBlockStatusLoading ||
          isCollectionAgenciesLoading ||
          isLoadingBucketList
        }
      >
        <>
          <NotificationBanner notifications={notifications} />
          {applicantBlockingNotification}
          {contractData ? (
            <TabsComponent
              collectionsBlockAutoReferralData={
                collectionsBlockAutoReferralData
              }
              collectionsData={collectionsData}
              contractData={contractData}
              contractUuid={contractUuid}
              externalContractUUID={externalContractUUID}
              isArrearsToBeManagedByHeidi={isArrearsToBeManagedByHeidi}
              isCollectionsDataError={isCollectionsDataError}
              isCompass={isCompass}
              isInternalOpsUser={isInternalOpsUser}
              monetaContractData={monetaContractData}
              pauseCommunicationsData={pauseCommunicationsData}
            />
          ) : (
            <NoDataAvailable />
          )}
          {applicantBlockModals}
          {modals.map((modal, idx) => (
            <Fragment key={modal?.ctaProps.key ?? idx}>{modal?.Modal}</Fragment>
          ))}
        </>
      </QueryStateRouter>
    </Card>
  );
};
