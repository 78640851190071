import { Helmet } from 'react-helmet';
import { useFeatureFlags, useOriginator } from '@hooks';
import {
  OriginatorDisplayNames,
  OriginatorDisplayNamesHeyLight,
  Originators,
} from '@utils/constants';

interface IGetFavicon {
  canUseHeyLightBranding: boolean;
  isHeidiPay: boolean;
  isProdFeatureFlag: boolean;
  publicUrl?: string;
}

const getFavicon = ({
  canUseHeyLightBranding,
  isHeidiPay,
  isProdFeatureFlag,
  publicUrl,
}: IGetFavicon) => {
  let faviconSuffix = '';

  if (canUseHeyLightBranding) {
    faviconSuffix = '_heylight';
  } else if (!isHeidiPay && !isProdFeatureFlag) {
    faviconSuffix = '_pagolight';
  }

  return `${publicUrl}/media/logos/favicon${faviconSuffix}.ico`;
};

export const BrowserTabThemeController = () => {
  const { isHeidiPay, originator } = useOriginator();
  const { PUBLIC_URL: publicUrl } = process.env;
  const { canUseHeyLightBranding, isProdFeatureFlag } = useFeatureFlags();

  const FeatureFlagOriginatorDisplayName = canUseHeyLightBranding
    ? OriginatorDisplayNamesHeyLight
    : OriginatorDisplayNames;

  const favicon = getFavicon({
    canUseHeyLightBranding,
    isHeidiPay,
    isProdFeatureFlag,
    publicUrl,
  });

  return (
    <Helmet>
      <title id="tab-id">
        {isProdFeatureFlag
          ? FeatureFlagOriginatorDisplayName[Originators.HEIDIPAY]
          : FeatureFlagOriginatorDisplayName[originator]}{' '}
        | Portal
      </title>
      <link href={favicon} id="tab-favicon" rel="icon" type="image/x-icon" />
      {canUseHeyLightBranding ? (
        <style>{`
          html, body {
            font-family: 'HeyLight Sans', sans-serif;
          }
        `}</style>
      ) : null}
    </Helmet>
  );
};
