import { FormattedMessage } from 'react-intl';
import { LinkThemed } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';

const pagolightUrl = 'https://pagolight.it/aiuto.html';
const heyLightUrl = 'https://heylight.com/it/it/assistenza';

interface ICompassSupportTextAndLink {
  canUseHeyLightBranding: boolean;
  supportTextKey: string;
  translate: Translate;
}

export const CompassSupportTextAndLink = ({
  canUseHeyLightBranding = false,
  supportTextKey,
  translate,
}: ICompassSupportTextAndLink) => {
  const url = canUseHeyLightBranding ? heyLightUrl : pagolightUrl;

  return (
    <FormattedMessage
      id={supportTextKey}
      values={{
        link: (
          <LinkThemed
            href={url}
            rel="noreferrer"
            sx={{ fontWeight: '500' }}
            target="_blank"
          >
            {translate('LINK.CONTACT_SUPPORT')}
          </LinkThemed>
        ),
      }}
    />
  );
};
