import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalCollectionsListManualBuckets } from '@schemas/opsPortal/types/collectionsListManualBuckets';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';

export const useGetManualBucketsListQuery = () => {
  const {
    data: bucketListData,
    error: bucketListError,
    isLoading: isLoadingBucketList,
  } = useQuery<IOpsPortalCollectionsListManualBuckets, AxiosError>(
    [QueryKeys.LIST_BUCKETS],
    () => fetchDataFromEndpoint(Endpoints.ListBuckets),
  );

  return {
    bucketListData,
    bucketListError,
    isLoadingBucketList,
  };
};
