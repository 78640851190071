/* eslint-disable max-len */
/* eslint-disable max-lines */
import { Navigate, Route, Routes } from 'react-router-dom';
import { Logout } from '@auth';
import {
  useFeatureFlags,
  useHasPermission,
  useMerchantSettingGroup,
  useOriginator,
  usePortal,
} from '@hooks/index';
import {
  AccountSettings,
  APICalls,
  ApplicationDetails,
  Applications,
  BankAccountReconciliations,
  BlockedApplicants,
  Collections,
  ConsumerDetails,
  Consumers,
  ContractDetails,
  Contracts,
  Documents,
  ErrorPage,
  InStore,
  MediobancaReconciliations,
  MerchantDetails,
  MerchantGuide,
  Merchants,
  MultiLanguageGuide,
  OrderDetailsConsumer,
  OrderDetailsMerchant,
  Orders,
  Organisation,
  OverpaidContracts,
  PausedContracts,
  PrivacyPolicy,
  SettlementDetails,
  Settlements,
  Tracking,
  UnbalancedAllocationDetails,
  UnbalancedAllocations,
  Users,
  Utilities,
} from '@pages';
import { PendingAllocations } from '@pages/BankAccountReconciliations/PendingAllocations';
import { guides } from '@pages/Help/guideUrls';
import { TerminableContracts } from '@pages/TerminableContracts/TerminableContracts';
import { scopes } from '@utils';
import { PathnamePrefixes, Views } from '@utils/constants';
import { BankAccountReconciliationView, PageRoutes } from '@utils/enums';
import { AuthRedirect } from './AuthRedirect';
import { AuthRoute } from './AuthRoute';
import { getInitialRoute } from './getInitialRoute';
import { ProtectedRoute } from './ProtectedRoute';
import { routeFactory } from './routeFactory';
import { useHeyLightQueryString } from './useHeyLightQueryString';

const permissionConsumerCanViewPaymentPlans =
  'consumer_portal.can_view_payment_plans';
const permissionMerchantCanViewPaymentPlans =
  'merchant_portal.can_view_payment_plans';
const permissionOpsViewApplication = 'ops_portal.view_application';
const permissionOpsViewContract = 'ops_portal.view_contract';
const permissionOpsViewMerchant = 'ops_portal.view_merchant';
const permissionOpsCanViewBankAcctStatementLines =
  'ops_portal.can_view_bank_account_statement_lines';
const permissionConsumerChangeCardGuide = 'consumer_portal.can_change_card';
const permissionMerchantCanViewSalesAssistantLimitedAccess =
  'merchant_portal.can_view_sales_assistant_limited_access';
const permissionCanViewMerchantApplications =
  'merchant_portal.can_view_applications';

export const PortalRoutes = () => {
  const { isConsumerPortal, isMerchantPortal, isOpsPortal } = usePortal();
  const { hideOrdersPage, showApplicationsPage } = useMerchantSettingGroup();
  const { canUseNewLoginPage } = useFeatureFlags();

  useHeyLightQueryString();

  const canViewOrders =
    useHasPermission([
      permissionConsumerCanViewPaymentPlans,
      permissionMerchantCanViewPaymentPlans,
    ]) && !hideOrdersPage;
  const canMerchantViewMyOrders =
    useHasPermission([permissionMerchantCanViewSalesAssistantLimitedAccess]) &&
    !hideOrdersPage;
  const canMerchantViewApplications =
    useHasPermission([permissionCanViewMerchantApplications]) &&
    Boolean(showApplicationsPage);
  const canMerchantViewMyApplications =
    useHasPermission([permissionMerchantCanViewSalesAssistantLimitedAccess]) &&
    Boolean(showApplicationsPage);

  const { getPathnamePrefixForOriginator: getPathnamePrefix } = useOriginator();

  const initialRoute = getInitialRoute({
    isOpsPortal,
    canViewOrders,
    canMerchantViewMyOrders,
    canMerchantViewMyApplications,
    canMerchantViewApplications,
  });

  const createRoute = (
    basePath: string,
    component: JSX.Element | null = null,
  ) =>
    routeFactory({
      basePath,
      canUseNewLoginPage,
      component,
      getPathnamePrefix,
    });

  return (
    <Routes>
      {createRoute(`${PageRoutes.Auth}/*`, <AuthRoute />)}

      <Route
        element={
          <ProtectedRoute
            scopes={[
              'consumer_portal.change_consumeruser',
              'merchant_portal.can_view_account_info',
              permissionOpsViewMerchant,
            ]}
          >
            <AccountSettings />
          </ProtectedRoute>
        }
        path={PageRoutes.AccountSettings}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <Contracts />
          </ProtectedRoute>
        }
        path={PageRoutes.AllContracts}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <ContractDetails />
          </ProtectedRoute>
        }
        path={`${PageRoutes.AllContracts}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewApplication]}>
            <APICalls />
          </ProtectedRoute>
        }
        path={PageRoutes.ApiCalls}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={[
              permissionOpsViewApplication,
              permissionCanViewMerchantApplications,
            ]}
          >
            <Applications />
          </ProtectedRoute>
        }
        path={PageRoutes.Applications}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={[permissionMerchantCanViewSalesAssistantLimitedAccess]}
          >
            <Applications isMyApplications={true} />
          </ProtectedRoute>
        }
        path={PageRoutes.MyApplications}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <ApplicationDetails />
          </ProtectedRoute>
        }
        path={`${PageRoutes.Applications}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={['merchant_portal.can_view_awaiting_dispatch_orders']}
          >
            <Orders view={Views.AWAITING_DISPATCH} />
          </ProtectedRoute>
        }
        path={PageRoutes.AwaitingConfirmation}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={['merchant_portal.can_view_awaiting_dispatch_orders']}
          >
            <OrderDetailsMerchant myOrder={false} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.AwaitingConfirmation}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <Contracts view={Views.AWAITING_DISPATCH} />
          </ProtectedRoute>
        }
        path={PageRoutes.AwaitingDispatch}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <ContractDetails view={Views.AWAITING_DISPATCH} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.AwaitingDispatch}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsCanViewBankAcctStatementLines]}>
            <BankAccountReconciliations
              view={BankAccountReconciliationView.BankAccountReconciliations}
            />
          </ProtectedRoute>
        }
        path={PageRoutes.BankAccountReconciliations}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.can_view_blocked_applicants']}>
            <BlockedApplicants />
          </ProtectedRoute>
        }
        path={PageRoutes.BlockedApplicants}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionConsumerChangeCardGuide]}>
            <MultiLanguageGuide
              guides={guides.CARD_CHANGE}
              translationRef="PAGE.CARD_CHANGE_GUIDE.REDIRECTING_TO_CARD_CHANGE_GUIDE"
            />
          </ProtectedRoute>
        }
        path={PageRoutes.CardChangeGuide}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <Collections />
          </ProtectedRoute>
        }
        path={PageRoutes.Collections}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <ContractDetails view={Views.COLLECTIONS} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.Collections}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.view_consumer']}>
            <Consumers />
          </ProtectedRoute>
        }
        path={PageRoutes.Consumers}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.view_consumer']}>
            <ConsumerDetails />
          </ProtectedRoute>
        }
        path={`${PageRoutes.Consumers}/:consumerUuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={['merchant_portal.can_create_a_new_plan']}>
            <InStore />
          </ProtectedRoute>
        }
        path={PageRoutes.CreateNewPlan}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionConsumerCanViewPaymentPlans]}>
            <Documents />
          </ProtectedRoute>
        }
        path={PageRoutes.Documents}
      />

      <Route
        element={
          <ProtectedRoute scopes={scopes.merchant}>
            <MultiLanguageGuide
              guides={guides.INSTORE_SALES}
              translationRef="PAGE.INSTORE_SALES_GUIDE.REDIRECTING_TO_INSTORE_SALES_GUIDE"
            />
          </ProtectedRoute>
        }
        path={PageRoutes.InStoreSalesGuide}
      />

      <Route
        element={
          <ProtectedRoute scopes={scopes.all}>
            <MerchantGuide />
          </ProtectedRoute>
        }
        path={PageRoutes.MerchantGuide}
      />

      <Route
        element={
          <ProtectedRoute scopes={scopes.merchant}>
            <PrivacyPolicy />
          </ProtectedRoute>
        }
        path={PageRoutes.MerchantPrivacyPolicy}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewMerchant]}>
            <Merchants />
          </ProtectedRoute>
        }
        path={PageRoutes.Merchants}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewMerchant]}>
            <MerchantDetails />
          </ProtectedRoute>
        }
        path={`${PageRoutes.Merchants}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={[permissionMerchantCanViewSalesAssistantLimitedAccess]}
          >
            <Orders myOrders={true} />
          </ProtectedRoute>
        }
        path={PageRoutes.MyOrders}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={[permissionMerchantCanViewSalesAssistantLimitedAccess]}
          >
            <OrderDetailsMerchant myOrder={true} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.MyOrders}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={[
              permissionConsumerCanViewPaymentPlans,
              permissionMerchantCanViewPaymentPlans,
            ]}
          >
            <Orders />
          </ProtectedRoute>
        }
        path={PageRoutes.Orders}
      />

      {isMerchantPortal ? (
        <Route
          element={
            <ProtectedRoute scopes={[permissionMerchantCanViewPaymentPlans]}>
              <OrderDetailsMerchant myOrder={false} />
            </ProtectedRoute>
          }
          path={`${PageRoutes.Orders}/:uuid`}
        />
      ) : null}

      {isConsumerPortal ? (
        <Route
          element={
            <ProtectedRoute scopes={[permissionConsumerCanViewPaymentPlans]}>
              <OrderDetailsConsumer />
            </ProtectedRoute>
          }
          path={`${PageRoutes.Orders}/:uuid`}
        />
      ) : null}

      <Route
        element={
          <ProtectedRoute
            scopes={['merchant_portal.can_edit_merchant_settings']}
          >
            <Organisation />
          </ProtectedRoute>
        }
        path={PageRoutes.OrgInfo}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <OverpaidContracts />
          </ProtectedRoute>
        }
        path={PageRoutes.OverpaidContracts}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <ContractDetails view={Views.OVERPAID_CONTRACTS} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.OverpaidContracts}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <TerminableContracts />
          </ProtectedRoute>
        }
        path={PageRoutes.TerminableContracts}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsViewContract]}>
            <ContractDetails view={Views.TERMINABLE_CONTRACTS} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.TerminableContracts}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.view_paused_contracts']}>
            <PausedContracts />
          </ProtectedRoute>
        }
        path={PageRoutes.PausedContracts}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.view_paused_contracts']}>
            <ContractDetails view={Views.PAUSED_CONTRACTS} />
          </ProtectedRoute>
        }
        path={`${PageRoutes.PausedContracts}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsCanViewBankAcctStatementLines]}>
            <PendingAllocations />
          </ProtectedRoute>
        }
        path={PageRoutes.PendingAllocations}
      />

      <Route
        element={
          <ProtectedRoute scopes={['merchant_portal.can_view_reconciliation']}>
            <Settlements />
          </ProtectedRoute>
        }
        path={PageRoutes.Settlements}
      />

      <Route
        element={
          <ProtectedRoute scopes={['merchant_portal.can_view_reconciliation']}>
            <SettlementDetails />
          </ProtectedRoute>
        }
        path={`${PageRoutes.Settlements}/:settlementsId`}
      />

      <Route
        element={
          <ProtectedRoute scopes={[permissionOpsCanViewBankAcctStatementLines]}>
            <BankAccountReconciliations
              view={BankAccountReconciliationView.Suspense}
            />
          </ProtectedRoute>
        }
        path={PageRoutes.Suspense}
      />

      <Route
        element={
          <ProtectedRoute scopes={['merchant_portal.can_create_a_new_plan']}>
            <Tracking />
          </ProtectedRoute>
        }
        path={`${PageRoutes.Track}/:contractId`}
      />

      <Route
        element={
          <ProtectedRoute
            scopes={['merchant_portal.can_view_mediobanca_reconciliation']}
          >
            <MediobancaReconciliations />
          </ProtectedRoute>
        }
        path={PageRoutes.Transactions}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.can_view_par_groups']}>
            <UnbalancedAllocations />
          </ProtectedRoute>
        }
        path={PageRoutes.UnbalancedAllocations}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.can_view_par_groups']}>
            <UnbalancedAllocationDetails />
          </ProtectedRoute>
        }
        path={`${PageRoutes.UnbalancedAllocations}/:uuid`}
      />

      <Route
        element={
          <ProtectedRoute scopes={['merchant_portal.can_edit_user_settings']}>
            <Users />
          </ProtectedRoute>
        }
        path={PageRoutes.Users}
      />

      <Route
        element={
          <ProtectedRoute scopes={['ops_portal.can_access_utilities']}>
            <Utilities />
          </ProtectedRoute>
        }
        path={PageRoutes.Utilities}
      />

      <Route element={<ErrorPage />} path={PageRoutes.Error} />

      <Route element={<Logout />} path={PageRoutes.Logout} />

      <Route
        element={
          <ProtectedRoute scopes={scopes.all}>
            <Navigate to={initialRoute} />
          </ProtectedRoute>
        }
        path="/"
      />

      {canUseNewLoginPage ? (
        <>
          <Route
            element={<AuthRedirect country="IT" />}
            path={`${PathnamePrefixes.PAGOLIGHT}/auth/*`}
          />
          <Route
            element={<AuthRedirect country="IT" />}
            path={`${PathnamePrefixes.PAGOLIGHTPRO}/auth/*`}
          />
          <Route element={<AuthRedirect country="CH" />} path="auth/*" />
        </>
      ) : (
        <>
          <Route
            element={
              <Navigate replace={true} to={`${PathnamePrefixes.PAGOLIGHT}/`} />
            }
            path={'/HeyLight/IT*'}
          />
          <Route
            element={<Navigate replace={true} to="/auth/" />}
            path={'/HeyLight/CH/auth/*'}
          />
        </>
      )}

      <Route element={<Navigate to={PageRoutes.Error} />} path="*" />

      {/* 
        Calling the factory like this creates routes for "/pagolight" and "/pagolightpro" (or "/HeyLight/IT" and "/HeyLight/CH" after rebranding)
      */}
      {createRoute('')}
    </Routes>
  );
};
