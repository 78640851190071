import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '@utils';

interface IScrollTop {
  className: string;
}

export function ScrollTop({ className }: IScrollTop) {
  return (
    <div className={`scrolltop ${className}`} id="kt_scrolltop">
      <span className="svg-icon">
        <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Up-2.svg')}></SVG>
      </span>{' '}
    </div>
  );
}
